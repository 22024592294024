import React from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import { EGroupPartition } from '@wix/social-groups-serverless/dist/groups/types';
import { selectGroups } from 'store/selectors';
import { EGroupsNamespace } from 'store/groups/constants';

import { Fade } from 'wui/Fade';
import { List } from 'wui/List';
import { Show } from 'wui/Show';

import { EmptyGroupList } from '../../EmptyGroupList';

import { SELECT_GROUP_MODAL_GROUP_CARD } from './dataHooks';
import { GroupListItem, GroupListItemSkeleton } from './GroupListItem';

interface IProps {
  title: string;

  onReset(): void;
  onLoadMore(): void;
  onSelect(groupId: string): void;
}

export function RecommendedGroups(props: IProps) {
  const { onLoadMore, onReset, onSelect, title } = props;

  const { groups, meta, status } = useSelector(
    selectGroups(EGroupsNamespace.RECOMMENDED_DIALOG),
  );

  if (status.fetch?.loading) {
    return <List disablePadding>{getPlaceholders(10)}</List>;
  }

  if (!groups.length) {
    return (
      <EmptyGroupList
        bw
        variant="section"
        onReset={onReset}
        filters={{ title }}
        partition={EGroupPartition.ALL}
      />
    );
  }

  return (
    <InfiniteScroll
      initialLoad={false}
      useWindow={false}
      hasMore={!!meta.total && meta.total > groups.length}
      loadMore={onLoadMore}
      threshold={200}
    >
      <List disablePadding>
        {groups.map((group) => (
          <GroupListItem
            group={group}
            key={`group-to-post-in-${group.id}`}
            data-hook={SELECT_GROUP_MODAL_GROUP_CARD}
            onSelect={onSelect}
          />
        ))}
        <Show if={status.fetchMore?.loading}>{getPlaceholders(10)}</Show>
      </List>
    </InfiniteScroll>
  );
}

RecommendedGroups.displayName = 'RecommendedGroups';

function getPlaceholders(count: number) {
  return (
    <>
      {new Array(count).fill(0).map((_, index) => (
        <Fade key={index}>
          <GroupListItemSkeleton />
        </Fade>
      ))}
    </>
  );
}
